import * as React from "react"
import { Link } from "gatsby"
import SEO from '../components/seo'
import styles from '../components/typography.module.css';

// markup
const StyleSystemPage = () => {
  return (
    <main>
      <SEO title='Style System' />
      <h1 className={styles.h1}>Siden ble ikke funnet</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
      </p>
      <h2>Siden ble ikke funnet</h2>
      <p>Vanlig tekst her</p>
      <h3>Siden ble ikke funnet</h3>
      <p>Vanlig tekst her</p>
      <h4>Siden ble ikke funnet</h4>
      <p>Vanlig tekst her</p>
      <h5>Siden ble ikke funnet</h5>
      <p>Vanlig tekst her</p>
      <p>
        <Link  to="/">Lenke til p</Link>
      </p>
      <p className={styles.lead}>
        <Link  to="/">Lenke til p</Link>
      </p>
    </main>
  )
}

export default StyleSystemPage;
